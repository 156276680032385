import Vue from "vue";
import RRate from "./RRate.vue";
import Collapsible from "./Collapsible.vue";
import RTree from "./RTree.vue";
import MiniPaging from "./MiniPaging.vue";

Vue.component("r-rate", RRate);
Vue.component("r-collapsible", Collapsible);
Vue.component("r-tree", RTree);
Vue.component("r-minipaging", MiniPaging);
